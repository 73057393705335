//
// Subheader
//

.subheader {
	display: flex;
	align-items: center;

	// Subheader Separator
	.subheader-separator {
		display: block;
	    width: 12px;
	    height: 4px;
	    border-radius: 2px;

		&.subheader-separator-ver {
			width: 1px;
    		height: 22px;
		}
	}

	// Subheader Solid Mode
	&.subheader-solid {
		background-color: get($subheader-config, default, bg-color);
	}
}

// Desktop Mode
@include media-breakpoint-up(lg) {
    // Subheader
	.subheader {
		// Fixed subheader mode
		.header-fixed.subheader-fixed & {
			position: fixed;
			height: get($subheader-config, fixed, height);
			top: get($header-config, desktop, fixed, height);
			left: 0;
			right: 0;
			transition: all 0.3s ease;
			z-index: get($subheader-config, fixed, zindex);
			box-shadow: get($subheader-config, fixed, shadow);
			background-color: get($subheader-config, default, bg-color);
			border-top: 1px solid get($subheader-config, default, border-color);
			margin: 0;
		}

		// Fixed header, minimized header and fixed subheader
		.header-fixed[data-header-scroll="on"].subheader-fixed & {
			top: get($header-config, desktop, fixed, height);
			transition: top 0.3s ease;
		}

		.aside-enabled.subheader-fixed & {
			left: get($aside-config, base, width);
		}
	}

    // Static Subheader Mode
	.subheader-enabled:not(.subheader-fixed) {
		// Content
		.content {
			padding-top: 0;
		}

		// Subheader Solid Mode
		.subheader.subheader-solid {
			background-color: get($subheader-config, fixed, bg-color);
			border-top: get($subheader-config, default, border-top);
			margin-bottom: get($page-padding, desktop);
		}
	}
}

// Tablet & Mobile Modes
@include media-breakpoint-down(md) {
    // Content & fixed subheader mode
	.subheader-enabled {
		.content {
			padding-top: 0;
		}

		.subheader.subheader-solid {
			margin-bottom: get($page-padding, tablet-and-mobile);
		}
	}
}
