@import "@aws-amplify/ui-react/styles.css";

[data-amplify-authenticator] {
--amplify-components-button-primary-background-color: hsl(111deg 54% 44%);
--amplify-components-button-primary-hover-background-color: hsl(111deg 54% 44%);
--amplify-components-authenticator-router-border-width: 0;
--amplify-components-authenticator-router-box-shadow: 0;
--amplify-components-authenticator-form-padding: 1rem 0 0 0;
--amplify-components-fieldcontrol-line-height: 2.95rem;
--amplify-components-textfield-border-color: rgba(0, 0, 0, 0.23);
--amplify-components-button-border-color: rgba(0, 0, 0, 0.23);
--amplify-components-textfield-focus-border-color: rgb(0, 126, 75);
--amplify-components-button-line-height: 22.5px;
--amplify-components-button-primary-background-color: rgb(0, 126, 75);
}

[data-amplify-authenticator] [data-amplify-container] {
    place-self: normal;
}